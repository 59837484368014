<template>
  <section
    style="max-width: 955px"
    class="mx-auto"
    >
    <v-row
      no-gutters
      >
      <v-col
        cols="12"
        md="8"
        class="px-3 px-md-0"
        >
        <posts
          feed
          ></posts>
      </v-col>

      <v-col
        v-if="$vuetify.breakpoint.lgAndUp && currentUser"
        style="position: relative"
        cols="12"
        md="4"
        >
        <div
          class="sticky pl-6"
          >
          <v-card
            flat
            class="rounded-lg mb-3"
            :to="'/p/' + currentUser.selectedProfile.slug"
            >
            <v-card-text>
              <div
                class="d-flex flex-row align-start"
                style="gap: 12px"
                >
                <v-avatar 
                  color="grey"
                  >
                  <v-img
                    v-if="currentUser.selectedProfile.picture"
                    :src="url + currentUser.selectedProfile.picture.url"
                    ></v-img>
                  <v-icon 
                    v-else
                    dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
                <div
                  class="fill-width"
                  >
                  <div
                    class="text-body-2 font-weight-medium"
                    >
                    {{ currentUser.selectedProfile.name }}
                  </div>
                  <div
                    class="caption"
                    >
                    @{{ currentUser.selectedProfile.slug }}
                  </div>
                </div>
              </div>

              <div
                class="fill-width text-center mt-3"
                >
                <v-btn
                  v-if="currentUser.selectedProfile.profilableType == 'Store'"
                  text
                  small
                  color="secondary"
                  :to="{ name: 'admin' }"
                  >
                  <v-icon
                    small
                    class="mr-1"
                    style="margin-top: -2px"
                    >
                    mdi-storefront
                  </v-icon>
                  <div>
                    Administrar mi tienda
                  </div>
                </v-btn>

                <v-btn
                  text
                  small
                  color="primary"
                  @click="signout"
                  >
                  Cerrar sesión
                </v-btn>
              </div>
            </v-card-text>
          </v-card>

          <fairs
            v-if="currentUser.owner"
            ></fairs>

          <terms
            class="text-center"
            ></terms>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

const Search = () => import('@/components/Search')
const Recommendations = () => import('@/components/recommendations/Recommendations')
const Posts = () => import('@/components/posts/Posts')
const Stories = () => import('@/components/stories/Stories')
const Terms = () => import('@/components/shared/Terms')
const Fairs = () => import('@/components/fairs/Fairs')

export default {
  name: 'Home',
  
  data: () => ({
    posts: []
  }),

  components: {
    Posts, Stories, Recommendations, Search, Terms, Fairs
  },

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  methods: {
    signout () {
      localStorage.removeItem('auth_token')
      localStorage.removeItem('order_token')
      this.$router.go()
    },
  }
}
</script>

<style>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
}
</style>
